import { Injectable } from '@angular/core';

import { ExternalChatConnection } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalChatConnectionsService extends GenericObjectsService<ExternalChatConnection> {
  protected baseUrl = '/external-chat-connections';

  addMicrosoftTeamsConnection(connectionId: string): Promise<void> {
    return this.apiService.postPromise(`${this.baseUrl}/${connectionId}/microsoft-teams-connection`, {});
  }
}
